:global {

  .login_page {
    font-family: "Rubik", sans-serif;
    font-weight: 500;
    height: 100%;
    color: #000;

    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    font,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      vertical-align: baseline;
    }

    ol,
    ul {
      list-style: none;
    }

    table {
      border-collapse: separate;
      border-spacing: 0;
    }

    caption,
    th,
    td {
      text-align: left;
      font-weight: 400;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
      content: "";
    }

    blockquote,
    q {
      quotes: "" "";
    }

    a,
    a:hover,
    a:active {
      color: inherit;
      text-decoration: none;
    }

    section {
      font-size: 1rem;
    }

    strong {
      font-weight: bold;
    }

    * {
      text-rendering: optimizeLegibility;
    }

    button {
      cursor: pointer;
    }

    .only-mob {
      display: none;
    }

    .only-desk {
      display: block;
    }

    .snap {
      width: 90%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    .hidden {
      display: none;
    }

    /* ol.numer-bullet{
  list-style: decimal;
} */

    ol {
      margin-left: 0;
      padding-left: 0;
      counter-reset: item;
      list-style-position: inside;
      text-indent: -2em;
      padding-left: 2em;
    }

    ol>li {
      margin-left: 0;
      padding-left: 0;
      counter-increment: item;
      list-style: none inside;
      margin-bottom: 10px;
    }

    ol>li:before {
      content: counter(item);
      padding: 3px 8px;
      margin-right: 0.5em;
      background: #93358d;
      color: #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      font-weight: 600;
    }

    #home h2 {
      color: #3a3a3a;
      font-size: 1.6em;
      display: inline-block;
      max-width: 90%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 46px;
    }

    .btn-anim-basic {
      position: relative;
      -webkit-transition: background-color 0.2s ease, -webkit-transform 0.2s ease;
      transition: background-color 0.2s ease, -webkit-transform 0.2s ease;
      -o-transition: background-color 0.2s ease, transform 0.2s ease;
      transition: background-color 0.2s ease, transform 0.2s ease;
      transition: background-color 0.2s ease, transform 0.2s ease, -webkit-transform 0.2s ease;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .btn-violet {
      background: #93358d;
      border-radius: 5px;
      -webkit-transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      -o-transition: transform 0.2s ease, box-shadow 0.2s ease;
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      color: #FFFFFF;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      font-size: 1.6em;
      padding: 14px 26px;
      position: relative;
      display: inline-block;
      font-weight: 500;
      border: 0;
      outline: 0;
    }

    .btn-violet:hover {
      color: #FFFFFF;
      -webkit-box-shadow: 2px 2px 0 #c18dc5;
      box-shadow: 2px 2px 0 #c18dc5;
      -webkit-transform: translate(-2px, -2px);
      -ms-transform: translate(-2px, -2px);
      transform: translate(-2px, -2px);
    }

    .btn-violet.gradient {
      background: -webkit-gradient(linear, left top, right top, from(#893280), to(#6b2e75));
      background: -webkit-linear-gradient(left, #893280 0%, #6b2e75 100%);
      background: -o-linear-gradient(left, #893280 0%, #6b2e75 100%);
      background: linear-gradient(90deg, #893280 0%, #6b2e75 100%);
    }

    .btn-outline {
      background-color: #FFFFFF;
      border-radius: 5px;
      -webkit-transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      -o-transition: transform 0.2s ease, box-shadow 0.2s ease;
      transition: transform 0.2s ease, box-shadow 0.2s ease;
      transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
      border: 1px solid #1d1d1b;
      color: #3a3a3a;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      font-size: 1.6em;
      padding: 14px 26px;
      position: relative;
      display: inline-block;
    }

    .btn-outline:hover {
      color: #3a3a3a;
      -webkit-box-shadow: 2px 2px 0 #7f7f7f;
      box-shadow: 2px 2px 0 #7f7f7f;
      -webkit-transform: translate(-2px, -2px);
      -ms-transform: translate(-2px, -2px);
      transform: translate(-2px, -2px);
    }

    .btn-outline-white {
      border-radius: 5px;
      -webkit-transition: all 0.2s ease;
      -o-transition: all 0.2s ease;
      transition: all 0.2s ease;
      border: 1px solid #FFFFFF;
      color: #FFFFFF;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .btn-outline-white:hover {
      color: #3a3a3a;
      -webkit-box-shadow: 2px 2px 0 #FFFFFF;
      box-shadow: 2px 2px 0 #FFFFFF;
      -webkit-transform: translate(-2px, -2px);
      -ms-transform: translate(-2px, -2px);
      transform: translate(-2px, -2px);
    }

    .beneficios-suma {
      flex: 33.33;
      /* margin-top: 20px; */
      margin-bottom: 20px;
    }

    .list {
      z-index: 1;
      max-height: 400px;
      overflow: auto;
    }

    .quienes-somos {
      flex: 40%;
      font-family: "Rubik", sans-serif;
      font-weight: 200;
      font-size: 16px;
      text-align: left;
      /* padding: 0 20px; */
      margin: 20px 20px;
    }

    .content-byc {
      padding: 0;
      background-color: white;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.2s ease-out;
    }

    .collapsible {
      color: #3a3a3a;
      font-family: Rubik;
      font-weight: 200;
      font-style: normal;
      font-size: 16px;
      text-transform: uppercase;
      border-color: #fff;
      background: #fff;
      border: none;
      margin: 15px 0;
      padding: 0;
    }

    .collapsible:focus {
      outline: none;
    }

    .collapsible:after {
      content: '\02795';
      /* Unicode character for "plus" sign (+) */
      font-size: 13px;
      color: white;
      float: right;
      margin-left: 5px;
      color: #3a3a3a;
    }

    .active:after {
      content: "\2796";
      /* Unicode character for "minus" sign (-) */
      color: #3a3a3a;
    }

    .no-bottom-border {
      border-bottom: none !important;
    }

    .section-requisitos {
      margin-bottom: 35px;
      text-align: center;
    }

    .section-requisitos .text {
      margin-bottom: 35px;
    }

    .section-requisitos .text h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 3.1em;
      font-weight: 400;
      color: #3a3a3a;
    }

    .section-requisitos .text h1 {
      margin: 5px !important;
    }

    .listado-requisitos {
      margin: 30px 0;
    }

    .listado-requisitos li {
      font-family: "Rubik", sans-serif;
      font-weight: 200;
      font-size: 18px;
      padding: 5px 30px;
      color: #3a3a3a;
      list-style-position: outside;
    }

    .listado-requisitos li:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      background-image: url('/assets/imgs/check-icon-lista.svg');
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 2em;
    }

    .listado-requisitos.micomercioli li:before {
      content: '';
      display: inline-block;
      height: 1em;
      width: 1em;
      background-image: url('/assets/imgs/check-icon-lista.svg');
      background-size: contain;
      background-repeat: no-repeat;
      padding-left: 2em;
    }

    .requisitos>div {
      flex: 50;
      margin: 5px;
      background-color: #f5c400;
      padding: 50px 10px;
      text-align: left;
      width: 50%;
    }

    #menu-input {
      display: none;
      opacity: 0;
    }

    nav.topbar {
      position: relative;
      background-color: #FFFFFF;
    }

    nav.topbar .topbar-logo--mobile {
      display: none;
      height: 54px;
      padding-top: 13px;
      padding-bottom: 13px;
    }

    nav.topbar .topbar-logo--mobile img {
      height: 100%;
    }

    nav.topbar .navigation {
      display: block;
    }

    nav.topbar label.btn-menu {
      display: none;
      position: absolute;
      top: 9px;
      right: 9px;
      border: none;
      outline: none;
      width: 36px;
      height: 36px;
      border-radius: 5px;
      background-color: transparent;
      background-image: url(/assets/imgs/topbar-btn-menu.svg);
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: 36px 72px;
      cursor: pointer;
    }

    nav.topbar label.btn-menu:hover {
      background-color: #fce521;
    }

    nav.topbar label.btn-menu.active {
      background-position: 0 -36px;
    }

    nav.topbar .nav-top {
      background-color: #fce521;
      height: 30px;
      padding-top: 4px;
      padding-bottom: 4px;
    }

    nav.topbar .nav1 {
      width: 90%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      font-size: 0;
      text-align: right;
    }

    nav.topbar .nav1 li {
      display: inline-block;
      width: 130px;
    }

    nav.topbar .nav1 li a {
      color: #3a3a3a;
    }

    nav.topbar .nav1 li a:hover {
      color: #3a3a3a;
      background-color: rgba(255, 255, 255, 0.7);
    }

    nav.topbar .nav-bot {
      background-color: #FFFFFF;
      font-size: 0;
      position: relative;
    }

    nav.topbar .topbar-logo--desktop {
      width: 130px;
      height: 36px;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      -ms-grid-column-align: left;
      justify-self: left;
      position: absolute;
      top: 22px;
      left: 0;
    }

    nav.topbar .nav2,
    nav.topbar .nav3 {
      position: relative;
    }

    nav.topbar .nav2 a,
    nav.topbar .nav3 a {
      display: block;
    }

    nav.topbar .nav3 a {
      width: 130px;
    }

    .row-banner,
    .row {
      display: flex;
      flex-wrap: wrap;
    }

    .comercio-table td {
      padding: 15px 0px;
    }

    .map-frame {
      width: 60%;
      height: 400px;
    }

    .detalle-com {
      flex: 100%;
      background: #95358c;
      color: #fce521;
      padding: 10px 25px;
      font-size: 48px;
      font-weight: 900;
      font-family: Montserrat;
      vertical-align: middle;
      line-height: 0.4;
      margin: 1px 0;
      padding: 15px;
    }

    .detalle-com span {
      color: #fce521;
      font-size: 18px;
      font-weight: 500;
      font-family: Montserrat;
      vertical-align: middle;
    }

    .img-flex-50 {
      flex: 50%;
    }

    .banner-a img {
      width: 100%;
    }

    .banner-a .desktop {
      display: block;
    }

    .banner-a .mobile {
      display: none;
    }

    .banner-b img {
      font-size: 0;
      display: inline-block;
      width: 50%;
      margin: 0;
    }

    footer {
      background-color: #943494;
      padding-top: 80px;
      padding-bottom: 60px;
    }

    footer ul.footer {
      display: -ms-grid;
      display: grid;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
      -ms-grid-columns: 0.7fr 1fr 1.2fr 1.1fr;
      grid-template-columns: 0.7fr 1fr 1.2fr 1.1fr;
    }

    footer .data img {
      width: 100%;
      max-width: 128px;
      margin-bottom: 22px;
    }

    footer .data p {
      font-weight: 300;
      font-size: 1.3em;
      color: #FFFFFF;
    }

    footer ul.links {
      font-weight: 300;
      font-size: 1.2em;
      color: #FFFFFF;
      line-height: 1.4em;
    }

    footer ul.links li {
      margin-bottom: 4px;
    }

    footer ul.links a {
      white-space: nowrap;
      display: inline-block;
    }

    footer ul.links a:hover {
      text-decoration: underline;
    }

    footer .sitemap {
      /* padding-top: 44px; */
    }

    footer h3 {
      display: inline-block;
      font-family: "Varela Round", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      height: 44px;
      color: #FFFFFF;
      position: relative;
    }

    footer .socialnetworks ul.snlinks {
      font-size: 0;
    }

    footer .socialnetworks ul.snlinks li {
      display: inline-block;
      font-size: 1rem;
    }

    footer .socialnetworks ul.snlinks li h3 {
      font-size: 2.2em;
    }

    footer .socialnetworks ul.snlinks li a {
      width: 22px;
      height: 22px;
      display: block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
    }

    footer .socialnetworks ul.snlinks li a.facebook {
      background-image: url(/assets/imgs/footer-link-facebook.svg);
    }

    footer .socialnetworks ul.snlinks li a.instagram {
      background-image: url(/assets/imgs/footer-link-instagram.svg);
    }

    footer .socialnetworks ul.snlinks li a:hover {
      -webkit-transition: -webkit-transform 0.2s ease;
      transition: -webkit-transform 0.2s ease;
      -o-transition: transform 0.2s ease;
      transition: transform 0.2s ease;
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
      -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
      transform: scale(1.15);
    }

    footer .socialnetworks ul.snlinks li a:active {
      -webkit-transition: -webkit-transform 0.2s ease;
      transition: -webkit-transform 0.2s ease;
      -o-transition: transform 0.2s ease;
      transition: transform 0.2s ease;
      transition: transform 0.2s ease, -webkit-transform 0.2s ease;
      -webkit-transform: scale(0.8);
      -ms-transform: scale(0.8);
      transform: scale(0.8);
    }

    footer .socialnetworks ul.snlinks li.title {
      margin-right: 20px;
    }

    footer .socialnetworks ul.snlinks li.snicon {
      margin-right: 6px;
    }

    footer .suscription span {
      font-weight: 300;
      font-size: 1.2em;
      color: #FFFFFF;
      line-height: 1.4em;
      display: block;
      margin-bottom: 6px;
    }

    footer .suscription input {
      height: 33px;
      width: 100%;
      max-width: 220px;
      border-radius: 7px;
      border: 1px solid #FFFFFF;
      background-color: transparent;
      font-weight: 300;
      color: #6e6e6e;
      font-size: 1.2em;
      line-height: 33px;
      padding-left: 11px;
      padding-right: 11px;
    }

    footer .suscription input::-webkit-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    footer .suscription input:-ms-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    footer .suscription input::-ms-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    footer .suscription input::placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    /* -------------------------------------------- HOME */
    section.slider {
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding-bottom: 54px;
    }

    section.promociones {
      padding-top: 30px;
      padding-bottom: 60px;
      text-align: center;
    }

    section.promociones .cuotas {
      width: 100%;
      position: relative;
      padding-top: 26px;
      padding-bottom: 26px;
    }

    section.promociones .cuotas ul {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }

    section.promociones .cuotas ul img {
      width: 100%;
      height: auto;
      max-width: 276px;
    }

    section.tarjetaultra {
      text-align: center;
      padding-top: 76px;
      padding-bottom: 80px;
      margin-bottom: 20px;
      background-image: url(/assets/imgs/home-rayasbg.svg);
      background-size: 170px 200px;
      background-repeat: no-repeat;
      background-position: 85% bottom;
    }

    section.tarjetaultra .content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }

    section.tarjetaultra .image img {
      width: 100%;
    }

    section.tarjetaultra .text {
      text-align: left;
      padding-left: 46px;
      padding-top: 50px;
      width: 50%;
    }

    section.tarjetaultra .text h3 {
      font-family: "Varela Round", Sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #3a3a3a;
    }

    section.tarjetaultra .text ul,
    section.tarjetaultra .text ol {
      padding-top: 32px;
      font-weight: 300;
      font-size: 1.6em;
      margin-bottom: 30px;
    }

    section.tarjetaultra .text ul li,
    section.tarjetaultra .text ol li {
      margin-bottom: 18px;
    }

    /* -------------------------------------------- SECTION */
    section.intro {
      padding-bottom: 40px;
      border-bottom: 1px solid #93358d;
      padding-bottom: 34px;
      margin-bottom: 34px;
    }

    section.intro .content {
      width: 100%;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
    }

    section.intro .banner-a {
      margin-bottom: 30px;
    }

    section.intro .phrase {
      width: 90%;
      max-width: 680px;
      margin-left: auto;
      margin-right: auto;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 90px auto;
      grid-template-columns: 90px auto;
      grid-column-gap: 20px;
    }

    section.intro .phrase .img img {
      width: 100%;
    }

    section.intro h2 {
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
      font-weight: 300;
      font-size: 2.6em;
      color: #3a3a3a;
    }

    /* -------------------------------------------- RESUMEN SALDO */
    #resumensaldo section.resumensaldo-utilities {
      padding-top: 34px;
      width: 90%;
      max-width: 860px;
      margin-left: auto;
      margin-right: auto;
      color: #3a3a3a;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding-bottom: 100px;
    }

    #resumensaldo section.resumensaldo-utilities .saldo,
    #resumensaldo section.resumensaldo-utilities .resumen {
      width: 100%;
      max-width: 380px;
    }

    #resumensaldo section.resumensaldo-utilities .saldo form,
    #resumensaldo section.resumensaldo-utilities .resumen form {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 154px 56px;
      grid-template-rows: 154px 56px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    #resumensaldo section.resumensaldo-utilities .saldo {
      position: relative;
    }

    #resumensaldo section.resumensaldo-utilities .saldo::after {
      content: '';
      width: 170px;
      height: 200px;
      display: block;
      position: absolute;
      z-index: -9999;
      bottom: -70px;
      right: -50px;
      background-image: url(/assets/imgs/home-rayasbg.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    #resumensaldo section.resumensaldo-utilities .resumen {
      margin-left: 100px;
    }

    #resumensaldo section.resumensaldo-utilities h3 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 1.6em;
      display: block;
    }

    #resumensaldo section.resumensaldo-utilities h3 span {
      font-size: 2em;
      font-weight: 400;
      display: block;
      padding-top: 4px;
    }

    #resumensaldo section.resumensaldo-utilities input {
      height: 33px;
      width: 100%;
      max-width: 380px;
      border-radius: 7px;
      border: 1px solid #3a3a3a;
      background-color: #FFFFFF;
      font-weight: 300;
      color: #6e6e6e;
      font-size: 1.2em;
      line-height: 33px;
      padding-left: 11px;
      padding-right: 11px;
    }

    #resumensaldo section.resumensaldo-utilities input::-webkit-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #resumensaldo section.resumensaldo-utilities input:-ms-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #resumensaldo section.resumensaldo-utilities input::-ms-input-placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #resumensaldo section.resumensaldo-utilities input::placeholder {
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #resumensaldo section.resumensaldo-utilities span.clarification {
      font-size: 1.6em;
      font-weight: 300;
      display: block;
      margin-bottom: 12px;
    }

    #resumensaldo section.resumensaldo-utilities li+li {
      margin-top: 9px;
    }

    #resumensaldo section.paymentmethods {
      text-align: center;
      padding-bottom: 38px;
    }

    #resumensaldo section.paymentmethods h2 {
      color: #3a3a3a;
      font-size: 1.6em;
      display: inline-block;
      max-width: 90%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 46px;
    }

    #resumensaldo section.paymentmethods ul {
      font-size: 0;
    }

    #resumensaldo section.paymentmethods ul li {
      font-size: 1rem;
      display: inline-block;
      vertical-align: top;
    }

    #resumensaldo section.paymentmethods ul li .img {
      margin-bottom: 42px;
    }

    #resumensaldo section.paymentmethods ul li .img img {
      width: 100%;
    }

    #resumensaldo section.paymentmethods ul li span {
      display: block;
      font-weight: 300;
      font-size: 1.6em;
    }

    #resumensaldo section.atms {
      padding-bottom: 78px;
    }

    /* -------------------------------------------- PROMOS VIGENTES */
    #promosvigentes section.promosvigentes {
      padding-bottom: 46px;
    }

    #promosvigentes section.promosvigentes h1 {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 3.2em;
      margin-bottom: 44px;
      max-width: 860px;
      margin-left: auto;
      margin-right: auto;
    }

    #promosvigentes section.promosvigentes .promos-cuotas {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 244px 1fr;
      grid-template-columns: 244px 1fr;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: center;
      padding-bottom: 22px;
      border-bottom: 1px solid #93358d;
    }

    #promosvigentes section.promosvigentes .promos-cuotas+.promos-cuotas {
      padding-top: 22px;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .cuotas-img {
      height: auto;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .cuotas-img img {
      width: 100%;
      height: auto;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .list {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 46px 1fr 46px;
      grid-template-columns: 46px 1fr 46px;
      overflow-x: hidden;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .list ul {
      font-size: 0;
      overflow-x: hidden;
      overflow-y: hidden;
      white-space: nowrap;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .list ul li {
      font-size: 1rem;
      display: inline-block;
      width: 30%;
      height: 100%;
      margin-right: 5%;
      vertical-align: top;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .list ul li .container {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 100px 50px 74px 78px;
      grid-template-rows: 100px 50px 74px 78px;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .marca {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-weight: 300;
      font-size: 1.2em;
      text-transform: uppercase;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .marca * {
      width: 100%;
      margin-bottom: 0;
      text-align: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .descuento {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-weight: 300;
      font-size: 1.6em;
      color: #93358d;
      font-weight: bold;
      font-style: italic;
    }

    .cantidad>span {
      font-size: 2.8em !important;
      color: #95358c;
      font-family: Montserrat;
      font-weight: 900;
      font-style: normal;
    }

    .cantidad {
      color: #95358c;
      font-family: Montserrat;
      font-weight: normal;
      font-style: normal;
    }

    .comercio-vista {
      max-width: 860px;
    }

    .comercio-vista p {
      color: #3a3a3a;
      font-family: Rubik;
      font-weight: 200;
      font-style: normal;
      font-size: 16px;
    }

    .upper {
      text-transform: uppercase;
    }

    .comercio-table tr {
      height: 50px;
    }

    .comercio-table .td-title {
      padding-right: 20px;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .descuento .cantidad {
      padding: 0 10px;
      position: relative;
    }

    /* #promosvigentes section.promosvigentes .promos-cuotas .container .descuento .cantidad::after {
          content: '';
          position: relative;
          width: 120%;
          height: 2px;
          border-radius: 1px;
          background-color: yellow;
          display: block;
          margin-top: 4px;
          margin-left: -10%; } */
    #promosvigentes section.promosvigentes .promos-cuotas .container .descuento .cantidad span {
      font-size: 1.375em;
      line-height: 1em;
      display: block;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .info {
      font-weight: 300;
      font-size: 1.1em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    #promosvigentes section.promosvigentes .promos-cuotas .container .info .info-container div {
      width: 90%;
    }

    #promosvigentes section.promosvigentes button.next,
    #promosvigentes section.promosvigentes button.prev {
      width: 100%;
      border: 0;
      outline: 0;
      background-color: transparent;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    #promosvigentes section.promosvigentes button.next .icon,
    #promosvigentes section.promosvigentes button.prev .icon {
      border-radius: 99999px;
      background: rgb(255, 246, 198)
    }

    #promosvigentes section.promosvigentes button.next .icon img,
    #promosvigentes section.promosvigentes button.prev .icon img {
      display: block;
      width: 100%;
    }

    #promosvigentes section.promosvigentes button.next:hover .icon,
    #promosvigentes section.promosvigentes button.prev:hover .icon {
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      background-color: #fce521;
    }

    #promosvigentes section.premios {
      padding-bottom: 78px;
    }

    /* -------------------------------------------- COMERCIOS ADHERIDOS */
    #comerciosadheridos h1 {
      font-family: "Montserrat", sans-serif;
      font-weight: 400;
      font-size: 3.2em;
      margin-bottom: 44px;
      max-width: 860px;
    }

    #comerciosadheridos section.comerciosadheridos {
      margin-bottom: 94px;
    }

    #comerciosadheridos section.comerciosadheridos .tools {
      text-align: center;
    }

    #comerciosadheridos .filters {
      display: block;
      width: 100%;
      background-color: #cdcdcd;
      height: 80px;
      position: relative;
      border-bottom: 1px solid #93358d;
      margin-bottom: 64px;
    }

    #comerciosadheridos .filters ul.filters-nav {
      width: 90%;
      max-width: 860px;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    #comerciosadheridos .filters ul.filters-nav li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      position: relative;
    }

    #comerciosadheridos .filters ul.filters-nav .filtrar,
    #comerciosadheridos .filters ul.filters-nav .rubro,
    #comerciosadheridos .filters ul.filters-nav .localidad {
      font-family: "Montserrat", sans-serif;
      font-size: 1.6em;
      color: #3a3a3a;
      font-weight: 700;
    }

    #comerciosadheridos .filters ul.filters-nav .filtrar {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      border-right: 3px solid #FFFFFF;
    }

    #comerciosadheridos .filters ul.filters-nav .filtrar span {
      position: relative;
      display: block;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    #comerciosadheridos .filters ul.filters-nav .filtrar span::after {
      content: '';
      display: inline-block;
      position: relative;
      width: 26px;
      height: 26px;
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }

    #comerciosadheridos .filters ul.filters-nav .rubro {
      padding-left: 5%;
      -webkit-box-flex: 2;
      -ms-flex-positive: 2;
      flex-grow: 2;
    }

    #comerciosadheridos .filters ul.filters-nav .localidad {
      -webkit-box-flex: 2;
      -ms-flex-positive: 2;
      flex-grow: 2;
    }

    #comerciosadheridos .filters ul.filters-nav .buscar {
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
    }

    #comerciosadheridos .filters ul.filters-nav .buscar span.icon {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      display: none;
    }

    #comerciosadheridos .filters ul.filters-nav .list {
      display: none;
      position: absolute;
      top: 80px;
      left: 0;
      background-color: #cdcdcd;
      width: 100%;
    }

    #comerciosadheridos .filters ul.filters-nav input {
      display: none;
      opacity: 0;
    }

    #comerciosadheridos .filters ul.filters-nav input:checked~.list {
      display: block;
      opacity: 1;
    }

    #comerciosadheridos .filters ul.filters-nav input:checked~label::after {
      background-position: 0 -26px;
    }

    #comerciosadheridos .filters ul.filters-nav label {
      display: block;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      position: relative;
    }

    #comerciosadheridos .filters ul.filters-nav label:hover {
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: #FFFFFF;
    }

    #comerciosadheridos .filters ul.filters-nav label::after {
      content: '';
      display: inline-block;
      position: relative;
      width: 26px;
      height: 26px;
      background-size: 100%;
      background-position: 0 0;
      background-repeat: no-repeat;
    }

    #comerciosadheridos .filters ul.filters-nav .filters-items {
      padding: 12px 0;
    }

    #comerciosadheridos .filters ul.filters-nav .filters-items li {
      padding: 12px;
    }

    #comerciosadheridos .filters ul.filters-nav .filters-items li:hover {
      background-color: #908f8f;
    }

    #comerciosadheridos ul.brands {
      width: 90%;
      max-width: 860px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 40px;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 20px;
      grid-row-gap: 36px;
    }

    #comerciosadheridos ul.brands li {
      height: 156px;
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 126px auto;
      grid-template-rows: 126px auto;
    }

    #comerciosadheridos ul.brands li .img {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    #comerciosadheridos ul.brands li .promo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 1.2em;
      color: #575756;
      font-weight: 300;
    }

    #comerciosadheridos ul.brands li .promo span {
      display: inline-block;
      padding: 0 12px;
      background-color: #fce521;
      border-radius: 5px;
      height: 18px;
      line-height: 18px;
    }

    #comerciosadheridos ul.brands li .promo strong {
      font-weight: 700;
    }

    #comerciosadheridos section.ad {
      padding-bottom: 70px;
    }

    /* -------------------------------------------- QUIERO MI TARJETA */
    #quieromitarjeta h1 {
      font-family: "Montserrat", sans-serif;
      font-weight: 700;
      font-size: 1.6em;
      margin-bottom: 56px;
      margin-left: auto;
      margin-right: auto;
      color: #3a3a3a;
    }

    #quieromitarjeta section.quieromitarjeta {
      padding-top: 28px;
      padding-bottom: 60px;
      width: 40%;
      max-width: 596px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    #quieromitarjeta section.quieromitarjeta form {
      color: #3a3a3a;
    }

    #quieromitarjeta section.quieromitarjeta ul {
      margin-bottom: 28px;
    }

    #quieromitarjeta section.quieromitarjeta li,
    #quieromitarjeta section.quieromitarjeta label,
    #quieromitarjeta section.quieromitarjeta input,
    #quieromitarjeta section.quieromitarjeta select {
      width: 100%;
      display: block;
    }

    #quieromitarjeta section.quieromitarjeta li+li {
      padding-top: 40px;
    }

    #quieromitarjeta section.quieromitarjeta label {
      font-weight: 300;
      font-size: 2.6em;
      margin-bottom: 12px;
    }

    #quieromitarjeta section.quieromitarjeta input,
    #quieromitarjeta section.quieromitarjeta select {
      height: 33px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid #3a3a3a;
      background-color: #FFFFFF;
      font-weight: 300;
      color: #6e6e6e;
      font-size: 1.2em;
      line-height: 33px;
      padding-left: 11px;
      padding-right: 11px;
      text-align: center;
    }

    #quieromitarjeta section.quieromitarjeta input::-webkit-input-placeholder,
    #quieromitarjeta section.quieromitarjeta select::-webkit-input-placeholder {
      text-align: center;
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #quieromitarjeta section.quieromitarjeta input:-ms-input-placeholder,
    #quieromitarjeta section.quieromitarjeta select:-ms-input-placeholder {
      text-align: center;
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #quieromitarjeta section.quieromitarjeta input::-ms-input-placeholder,
    #quieromitarjeta section.quieromitarjeta select::-ms-input-placeholder {
      text-align: center;
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #quieromitarjeta section.quieromitarjeta input::placeholder,
    #quieromitarjeta section.quieromitarjeta select::placeholder {
      text-align: center;
      font-weight: 300;
      color: #6e6e6e;
      font-family: "Rubik", sans-serif;
    }

    #quieromitarjeta section.quieromitarjeta .captcha {
      margin-bottom: 100px;
    }

    @media screen and (min-width: 1024px) {
      nav.topbar .btn-purple {
        background-color: #93358d;
        border-radius: 5px;
        -webkit-transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        -o-transition: transform 0.2s ease, box-shadow 0.2s ease;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        color: #FFFFFF;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      nav.topbar .btn-purple:hover {
        color: #FFFFFF;
        -webkit-box-shadow: 2px 2px 0 #c18dc5;
        box-shadow: 2px 2px 0 #c18dc5;
        -webkit-transform: translate(-2px, -2px);
        -ms-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
      }

      nav.topbar .btn-empty {
        background-color: #FFFFFF;
        border-radius: 5px;
        -webkit-transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        transition: -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        -o-transition: transform 0.2s ease, box-shadow 0.2s ease;
        transition: transform 0.2s ease, box-shadow 0.2s ease;
        transition: transform 0.2s ease, box-shadow 0.2s ease, -webkit-transform 0.2s ease, -webkit-box-shadow 0.2s ease;
        border: 1px solid #1d1d1b;
        color: #3a3a3a;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
      }

      nav.topbar .btn-empty:hover {
        color: #3a3a3a;
        -webkit-box-shadow: 2px 2px 0 #7f7f7f;
        box-shadow: 2px 2px 0 #7f7f7f;
        -webkit-transform: translate(-2px, -2px);
        -ms-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
      }

      .nav-bot {
        height: 80px;
        width: 90%;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
        text-align: right;
      }

      .nav1 li+li {
        margin-left: 26px;
      }

      .nav1 li a {
        display: block;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        height: 22px;
        line-height: 22px;
        border-radius: 6px;
      }

      .nav1 li a:active {
        -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);
        transform: translateY(2px);
      }

      .nav2,
      .nav3 {
        display: inline-block;
        font-size: 1.2rem;
        text-align: center;
      }

      .nav2 li,
      .nav3 li {
        display: inline-block;
        padding-top: 23px;
        padding-bottom: 23px;
      }

      .nav2 li+li,
      .nav3 li+li {
        margin-left: 26px;
      }

      .nav2 a,
      .nav3 a {
        height: 34px;
        border-radius: 6px;
        line-height: 34px;
      }

      .nav2 a {
        padding-left: 12px;
        padding-right: 12px;
      }

      .nav2 a:hover {
        background-color: #fce521;
      }

      .nav2 a:active {
        -webkit-transform: translateY(2px);
        -ms-transform: translateY(2px);
        transform: translateY(2px);
      }

      .nav3 {
        margin-left: 26px;
      }
    }

    @media screen and (max-width: 1023px) {
      body {
        padding-top: 54px;
      }

      #menu-input:checked~nav.topbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99999;
      }

      #menu-input:checked~nav.topbar .navigation {
        position: absolute;
        top: 54px;
        left: 0;
      }

      #menu-input:checked~nav.topbar .navigation::after {
        -webkit-transition: all 0.6s ease;
        -o-transition: all 0.6s ease;
        transition: all 0.6s ease;
        display: block;
        opacity: 0.5;
      }

      #menu-input:checked~nav.topbar .navigation .menus {
        -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -o-transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), -webkit-transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
        -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
        transform: translate(0, 0);
      }

      #menu-input:checked~nav.topbar .btn-menu {
        background-position: 0 -36px;
      }

      nav.topbar {
        width: 100%;
        height: 54px;
        position: absolute;
        top: 0;
        left: 0;
      }

      nav.topbar .topbar-logo--desktop {
        display: none;
      }

      nav.topbar .topbar-logo--mobile {
        display: block;
        width: 50%;
      }

      nav.topbar label.btn-menu {
        display: block;
      }

      nav.topbar .navigation {
        z-index: -10;
        position: absolute;
        top: 54px;
        right: 0;
        width: 100%;
        height: calc(100vh - 54px);
        overflow-x: hidden;
      }

      nav.topbar .navigation::after {
        content: '';
        background-color: #000000;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: block;
        z-index: -1;
        opacity: 0;
      }

      nav.topbar .menus {
        width: 370px;
        max-width: 100%;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
        transform: translate(100%, 0);
        background-color: #FFFFFF;
      }

      nav.topbar .nav-top {
        height: auto;
        padding: 0;
        background-color: #FFFFFF;
      }

      nav.topbar .nav-bot {
        height: auto;
        width: 100%;
      }

      nav.topbar .nav1,
      nav.topbar .nav2,
      nav.topbar .nav3 {
        width: 100%;
        max-width: 100%;
        height: auto;
        text-align: left;
      }

      nav.topbar .nav1 li,
      nav.topbar .nav2 li,
      nav.topbar .nav3 li {
        width: 100%;
        display: block;
      }

      nav.topbar .nav1 li a,
      nav.topbar .nav2 li a,
      nav.topbar .nav3 li a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: 1.5rem;
        text-align: left;
        padding-left: 36px;
      }

      nav.topbar .nav2 li,
      nav.topbar .nav3 li {
        height: 56px;
      }

      nav.topbar .nav2 li:hover,
      nav.topbar .nav3 li:hover {
        background-color: #fce521;
      }

      nav.topbar .nav2 li a,
      nav.topbar .nav3 li a {
        line-height: 56px;
        background-color: rgba(255, 255, 255, 0.7);
      }

      nav.topbar .nav1 li {
        height: 50px;
        background-color: #fce521;
      }

      nav.topbar .nav1 li+li {
        margin-top: 1px;
      }

      nav.topbar .nav1 li a {
        line-height: 55px;
      }

      nav.topbar .nav2 {
        padding-bottom: 15px;
        border-bottom: 1px solid red;
      }

      nav.topbar .nav3 {
        padding-top: 15px;
        padding-bottom: 15px;
      }

      nav.topbar .nav3 li a {
        color: #93358d;
      }

      #comerciosadheridos ul.brands {
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      footer ul.footer {
        -ms-grid-columns: 2fr;
        grid-template-columns: 2fr;
      }

      #promosvigentes section.promosvigentes .promos-cuotas .list ul li {
        width: 47.5%;
      }
    }

    @media screen and (max-width: 759px) {
      .requisitos>div {
        width: 100%;
      }

      #quieromitarjeta section.quieromitarjeta {
        width: 100%;
      }

      footer ul.footer {
        text-align: center;
      }

      .only-mob {
        display: block;
      }

      .only-desk {
        display: none;
      }

      .quienes-somos {
        flex: 100%;
      }

      .beneficios-suma {
        flex: 100%;
      }

      .map-frame {
        width: 100%;
        height: 300px;
        ;
      }

      .img-flex-50 {
        flex: 100%;
      }

      .banner-a .desktop {
        display: none;
      }

      .banner-a .mobile {
        display: block;
      }

      .banner-b img {
        display: block;
        width: 100%;
      }

      section.tarjetaultra .content {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }

      section.tarjetaultra .image {
        width: 100%;
        margin-top: 35px;
      }

      section.tarjetaultra .text {
        padding-left: 0;
        width: 100%;
      }

      /* SLIDER */
      #resumensaldo section.resumensaldo-utilities {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
      }

      #resumensaldo section.resumensaldo-utilities .saldo,
      #resumensaldo section.resumensaldo-utilities .resumen {
        margin-left: auto;
        margin-right: auto;
      }

      #resumensaldo section.resumensaldo-utilities .saldo {
        margin-bottom: 100px;
      }

      #resumensaldo section.paymentmethods ul li {
        margin-bottom: 20px;
        max-width: 80%;
      }

      #resumensaldo section.paymentmethods ul li .img {
        margin-bottom: 10px;
      }

      #promosvigentes section.promosvigentes h1 {
        margin-bottom: 22px;
      }

      #promosvigentes section.promosvigentes .promos-cuotas {
        display: block;
      }

      #promosvigentes section.promosvigentes .promos-cuotas .cuotas-img {
        width: 70%;
        max-width: 276px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
      }

      #promosvigentes section.promosvigentes .promos-cuotas .cuotas-img img {
        height: auto;
      }

      #promosvigentes section.promosvigentes .promos-cuotas .list ul li {
        width: 100%;
      }

      #comerciosadheridos .filters ul.filters-nav .filtrar {
        padding-right: 5%;
      }

      #comerciosadheridos .filters ul.filters-nav .filtrar span::after {
        display: none;
      }

      #comerciosadheridos .filters ul.filters-nav .filtrar,
      #comerciosadheridos .filters ul.filters-nav .rubro,
      #comerciosadheridos .filters ul.filters-nav .localidad {
        font-size: 1.1em;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
      }

      #comerciosadheridos .filters ul.filters-nav .buscar .btn-violet {
        padding: 8px 12px;
      }

      #comerciosadheridos .filters ul.filters-nav .buscar span.text {
        display: none;
      }

      #comerciosadheridos .filters ul.filters-nav .buscar span.icon {
        display: inline-block;
      }

      #comerciosadheridos .filters ul.filters-nav .rubro,
      #comerciosadheridos .filters ul.filters-nav .localidad {
        padding-right: 5%;
      }

      #comerciosadheridos ul.brands {
        -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
    }

    .float {
      position: fixed;
      width: 60px;
      height: 60px;
      bottom: 40px;
      right: 40px;
    }

    .float img {
      width: 100%;
      filter: drop-shadow(5px 5px 3px rgba(0, 0, 0, .3));
    }

    .my-float {
      margin-top: 22px;
    }

  }
}