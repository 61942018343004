@import "@material/layout-grid/mdc-layout-grid";

.amountInput {
	font-size:3rem;
	text-align:center
}

@media (max-width: map-get($mdc-layout-grid-breakpoints, "tablet")) {
	.paymentIndicator > span {
		font-size: 1.6rem;
	}
}
