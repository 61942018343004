@import "@material/layout-grid/mdc-layout-grid";
@import '../../style/variables';

.bg {
	background: linear-gradient(0deg, rgba($theme-primary-darker, 1) 30%, rgba($mdc-theme-primary, 1) 80%);
	float: left;
	height: 180px;
	width: 100%;
	position: absolute;
}

.container {
	position: relative;
}

.captionTextPaySummary {
	color: rgba(0, 0, 0, 0.7);
}

.payButtonContainer {
	text-align: right;
}

.payButton {
	border-radius: 3px;
	width: auto;
}

.payButton > i {
	margin-right: 4px;
}

.downloadButton {
	border-radius: 3px;
	width: 100%;
}

@media (max-width: map-get($mdc-layout-grid-breakpoints, "tablet")) {
	.payButton {
		margin-left: -100%;
		font-size: 0.8rem;
	}

	.balanceText {
		font-size: 1.8rem;
	}
}

@media (min-width: map-get($mdc-layout-grid-breakpoints, "tablet")) {
	.payButton {
		width: 100%;
	}
}

@media (min-width: map-get($mdc-layout-grid-breakpoints, "desktop")) {
	.payButton {
		width: auto;
	}
}
