@import './variables';

@import '@rmwc/icon/icon.css';
@import '@material/theme/mdc-theme';
@import '@material/typography/mdc-typography';
@import '@material/switch/mdc-switch';
@import '@material/dialog/mdc-dialog';
@import '@material/list/mdc-list';
@import '@material/drawer/mdc-drawer';
@import '@material/dialog/mdc-dialog';
@import '@material/top-app-bar/mdc-top-app-bar';
@import '@material/icon-button/mdc-icon-button';
@import '@material/elevation/mdc-elevation';
@import '@material/layout-grid/mdc-layout-grid';
@import '@material/chips/mdc-chips';
@import '@material/card/mdc-card';
@import '@material/button/mdc-button';
@import '@material/textfield/mdc-text-field';
@import '@material/floating-label/mdc-floating-label';
@import '@material/notched-outline/mdc-notched-outline';
@import '@material/line-ripple/mdc-line-ripple';
@import '@material/ripple/mdc-ripple';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');

html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	font-family: 'Varela Round', sans-serif;
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v29/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

* {
	box-sizing: border-box;
}

#app {
	height: 100%;
  /*background: #ffffff;*/
}

.mdc-theme--dark {
	background-color: #333;
	color: #fff;
}

.mdc-theme--dark .mdc-card {
  color: #444;
}

#app .page {
  padding-top: 56px;
}

// Force the skeleton to show. See https://github.com/dvtng/react-loading-skeleton/issues/174
@media (prefers-reduced-motion) {
  .react-loading-skeleton {
    --pseudo-element-display: 'inline';
  }
}